import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Content from '../components/Content'
import Title from '../components/Title'
import { Columns, Column } from '../components/Columns'
import Back from '../components/Back'

const StyledLink = styled.a`
  &:hover {
    /* font-weight: bold; */
    color: #9c9c9c;
  }
`

const ImpressumPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <Content>
      <Title>Impressum</Title>
      <Columns>
        <Column width={4} margin={1}>
          <Title as="h2">INHALT</Title>
          © Zahnarztpraxis Breitenrain
          <br />
          <br />
          <Title as="h2">KONZEPT UND GESTALTUNG</Title>
          <StyledLink href="https://www.gebrauchsgraphik.net/">
            Büro für Gebrauchsgraphik AG, Vaduz
          </StyledLink>
          <br />
          <br />
          <Title as="h2">PROGRAMMIERUNG</Title>
          <StyledLink href="https://www.a45.li">A45 Anstalt, Vaduz</StyledLink>
        </Column>
        <Column width={4}>
          <Title as="h2">DISCLAIMER</Title>
          Dies ist die Website der Zahnarztpraxis Breitenrain. Die nachstehenden
          Ausführungen gelten für alle Seiten und Unterseiten dieser Website.
          Personen, welche auf diese Webseiten zugreifen und Informationen
          abrufen, erklären sich mit den nachfolgenden Bestimmungen
          einverstanden:
          <br />
          <br />
          <Title as="h2">BENUTZUNG</Title>
          Die Zahnarztpraxis Breitenrain unternimmt alle Anstrengungen, um einen
          sicheren Betrieb der Website sicherzustellen. Dennoch können Mängel
          wie z.B. Datenverlust oder -verfälschung, Virenbefall,
          Betriebsunterbruch etc. nicht ausgeschlossen werden. Der Zugriff auf
          die Webseiten erfolgt deshalb auf eigene Gefahr und in eigener
          Verantwortung.
          <br />
          <br />
          <Title as="h2">Gewährleistungsausschluss</Title>
          Die Zahnarztpraxis Breitenrain übernimmt keinerlei Gewähr für die
          Aktualität, Korrektheit, Vollständigkeit oder Qualität der
          bereitgestellten Informationen. Haftungsansprüche gegen die
          Zahnarztpraxis Breitenrain, die sich auf Schäden materieller oder
          ideeller Art beziehen, welche durch die Nutzung der dargebotenen
          Informationen verursacht wurden, sind grundsätzlich ausgeschlossen.
          Die Zahnarztpraxis Breitenrain behält es sich ausdrücklich vor, Teile
          der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
          verändern, zu ergänzen oder zu löschen.
          <br />
          <br />
          <Back to="/">Zurück</Back>
        </Column>
      </Columns>
    </Content>
  </Layout>
)

export default ImpressumPage
