import styled, { css } from 'styled-components'

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  margin-left: -16px;
  margin-right: -16px;
`

export const Column = styled.div`
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 1.65rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${props =>
    props.margin === 1 &&
    css`
      @media (max-width: 1024px) {
        margin-bottom: 3.3rem;
      }
    `}

  ${props =>
    props.width === 4 &&
    css`
      @media (min-width: 960px) {
        flex-basis: 50%;
        max-width: 50%;
      }
    `}

  ${props =>
    props.width === 8 &&
    css`
      @media (min-width: 960px) {
        flex-basis: 100%;
        max-width: 100%;
      }
    `}
`
