import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  svg polyline {
    transition: stroke 0.35s ease;
  }

  &:hover {
    /* font-weight: bold; */
    color: #9c9c9c;

    svg polyline {
      stroke: #9c9c9c;
    }
  }
`

const Svg = styled.svg`
  display: block;
  height: 16px;
  width: auto;
  margin-right: 10px;

  /* IE 11 hack */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 9px;
  }
`

const Back = ({ children, ...props }) => (
  <StyledLink {...props}>
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.84 18.24">
      <polyline
        points="9.46 17.88 0.71 9.13 9.49 0.35"
        fill="none"
        stroke="#435465"
        strokeMiterlimit={10}
      />
    </Svg>
    {children}
  </StyledLink>
)

export default Back
